<template>
  <div class="main">
    <el-form :model="ruleForm" ref="ruleForm">
      <div class="box justify box-bottom-border">
        <div class="box center mB20 ">
          <!-- <el-form-item label="分析时段" prop="period">
            <el-select @change="clearTable0" v-model="ruleForm.period">
              <el-option v-for="item in periodList" :key="item.name" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item> -->
          <!-- class="ml24" -->
          <el-form-item prop="startTime" label="分析时段">
            <el-date-picker placeholder="请选择时间" @change="clearTable" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss" v-model="ruleForm.startTime"> </el-date-picker>
          </el-form-item>
          <span class="line">-</span>
          <el-form-item prop="endTime">
            <el-date-picker @change="clearTable" placeholder="请选择时间" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss" v-model="ruleForm.endTime"> </el-date-picker>
          </el-form-item>
          <el-button @click="querryData" class="mL44 dataQuery">查询</el-button>
        </div>
        <span @click="queryHistory" class="mB20 dataQuery checkHistory pointer" :class="{ activeColorHis: ruleForm.period == 1 }">查看历史</span>
      </div>
      <div class="box center mB20 mT20">
        <el-form-item label="监测类型" prop="type" style="margin-right: 44px;">
          <el-select @change="changeType" v-model="ruleForm.type">
            <el-option v-for="item in typeList" :key="item.name" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="quotaList.length > 0"  label="监测指标" prop="quota">
          <el-radio-group @change="clearTable" v-model="ruleForm.quota">
            <el-radio v-for="item of quotaList" :key="item.id" :label="item.id">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <!--        <div v-if="ruleForm.period === 2" class="box center ml24 smallLabel">-->
        <div v-if="false" class="box center ml24 smallLabel">
          <span class="el-form-item__label wid96">参数录入</span>
          <el-form-item label="采样频率" prop="samplingFrequency">
            <el-input v-model="ruleForm.samplingFrequency" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item class="ml24" label="分段长度" prop="fractionLength">
            <el-input v-model="ruleForm.fractionLength" placeholder="请输入"></el-input>
          </el-form-item>
        </div>
      </div>
     
    </el-form>
    <template v-if="tableData.length">
      <div class="box-bottom-border" style="padding-bottom: 20px;">
        <el-table :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-table-column align="center" prop="target.code" label="测点编号"></el-table-column>
        <el-table-column align="center" prop="target.site" label="位置描述">
          <template slot-scope="scope">{{ scope.row.target.site || "—" }}</template>
        </el-table-column>

        <el-table-column align="center" prop="timeDuration" label="时段长度" v-if="ruleForm.period == 1">
          <template slot-scope="scope">{{ scope.row.timeDuration === 0 ? scope.row.timeDurationOther : scope.row.timeDuration | timeDuration }}</template>
        </el-table-column>
        <el-table-column align="center" prop="timePeriod" label="时间间隔(s)" v-if="ruleForm.period == 1">
          <template slot-scope="scope">{{ scope.row.timePeriod || "—" }}</template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="操作">
          <template slot-scope="scope">
            <span @click="look(scope.row)" class="pointer operate-txt">查看数据</span>
          </template>
        </el-table-column>
      </el-table>
      </div>
    </template>
    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>
    <div v-if="showTimeMain" class="chartMain">
      <div class="fs16 box center mT20 mB20">
        <img style="margin-right: 12px;width: 14px;height: 14px;" src="@/assets/images/fragment-title-icon.png" />
        <div style="margin-right: 20px;">测点编号：{{ targetName }}</div>
        <div v-if="targetHour" class="pl40">时域分析频率：{{ targetHour }}</div>
      </div>
      <div class="box justify smallTableMain flexTop">
        <div class="flex1">
          <!-- <div id="myChart"></div>
          <div id="myChartHis"></div> -->
          <div class="flex1 mB10">
            <div class="newBox mB10" v-show="!showNoData1">
              <p class="emColor title mB10">原始数据</p>
              <div class="myChart" id="myChart3"></div>
            </div>
            <div class="noDataMain" v-show="showNoData1">
              <p class="emColor title mB10">原始数据</p>
              <img src="../../../assets/images/noData.png" alt="" />
              <span>暂无数据</span>
            </div>
           
            <div class="newBox mB10" v-show="!showNoData2">
              <p class="emColor title mB10">基线校正</p>
              <div class="myChart" id="myChartHis1"></div>
            </div>
            <div class="noDataMain" v-show="showNoData2">
              <p class="emColor title mB10">基线校正</p>
              <img src="../../../assets/images/noData.png" alt="" />
              <span>暂无数据</span>
            </div>
            <div class="newBox mB10" v-show="!showNoData3">
              <p class="emColor title mB10">准静态效应</p>
              <div class="myChart" id="myChartHis2"></div>
            </div>
            <div class="noDataMain" v-show="showNoData3">
              <p class="emColor title mB10">准静态效应</p>
              <img src="../../../assets/images/noData.png" alt="" />
              <span>暂无数据</span>
            </div>
            <div class="newBox" v-show="!showNoData4">
              <p class="emColor title mB10">动态响应项</p>
              <div class="myChart" id="myChart"></div>
            </div>
            <div class="noDataMain" v-show="showNoData4">
              <p class="emColor title mB10">动态响应项</p>
              <img src="../../../assets/images/noData.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
        
        <table style="width:460px; margin-left:20px;">
           <tr>
             <!-- <td>准静态时域统计值</td> -->
             <th colspan='5'>准静态时域统计值</th>
           </tr>
            <tr v-for="(item,index) in msgList" :key="index">
             <td >{{item.name}}</td>
             <td >{{item.value}}</td>
           </tr>
           <!-- <tr>
             <td v-for="(item,index) in msgList" :key="index">{{item.name}}</td>
           </tr>
           <tr>
               <td v-for="(item,index) in msgList" :key="index">{{item.value}}</td>
           </tr> -->
        </table>

        <!-- <el-table :data="msgList" 
        v-loading="loadingSmall" 
        element-loading-text="拼命加载中"
         element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)">
                    <el-table-column align="center" label="准静态时域统计值">
                <el-table-column align="center" width="102px" prop="name"></el-table-column>
                <el-table-column align="center" width="202px" prop="value"></el-table-column>
              </el-table-column>
          </el-table> -->
      </div>
    </div>
    <template v-if="showHistoryMain">
      <p class="emColor mB16 mT20">历史峰值统计（测点编号：{{ targetName }}）</p>
      <el-table class="historyTable" :row-class-name="tableRowClassName" :data="historyTable" tooltip-effect="dark" style="width: 100%" v-loading="loadingHistory" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-table-column align="center" width="260px" prop="time" label="时间"></el-table-column>
        <el-table-column align="center" v-for="(item, index) in columnList" :key="index" prop="data" :label="item">
          <template slot-scope="scope">{{ scope.row.data[index].toFixed(3) }}</template>
        </el-table-column>
        <template slot="empty">
          <span class="iconfont iconzanwushuju-"></span>
          <span>暂无数据</span>
        </template>
      </el-table>
      <common-pagination class="pageMain" :pageSize="pageSize" :total="totalH" :currentPage="currentPageH" @handleCurrentChange="handleCurrentChangeH" />
    </template>
  </div>
</template>

<script>
import echarts from "echarts";
import CommonPagination from "../../../components/Pagination";
// let option = {
//   grid: {
//     top: "30",
//     left: "80",
//     right: "8",
//     bottom: "80"
//   },
//   tooltip: {
//     show: true,
//     trigger: "axis"
//   },
//   dataZoom: [
//     {
//       show: true,
//       realtime: true,
//       start: 40,
//       end: 60,
//       bottom: 10,
//       fillerColor: "rgba(47,50,51,1)"
//     },
//     {
//       type: "inside",
//       realtime: true,
//       start: 40,
//       end: 60
//     }
//   ],
//   xAxis: {
//     type: "category",
//     axisLabel: {
//       color: "#fff",
//       fontSize: 14,
//       formatter: function (value) {
//         return value.split(" ").join("\n");
//       }
//     },
//     axisLine: {
//       lineStyle: {
//         color: "#9D9D9D",
//         type: "dashed"
//       }
//     },
//     axisTick: {
//       show: false
//     },
//     // nameTextStyle:{
//     //   color:'#fff',
//     //   padding: [31, 0, 0,-40]
//     // },
//     // name:'时间',
//     data: []
//   },
//   yAxis: [
//     {
//       type: "value",
//       name: "",
//       nameTextStyle: {
//         color: "#fff",
//         fontSize: 14
//       },
//       axisLabel: {
//         color: "#fff",
//         fontSize: 14
//       },
//       axisLine: {
//         lineStyle: {
//           color: "#9D9D9D"
//         }
//       },
//       splitLine: {
//         lineStyle: {
//           color: "#9D9D9D",
//           type: "dashed"
//         }
//       },
//       axisTick: {
//         show: false
//       }
//     },
//     {
//       type: "value",
//       axisLine: {
//         lineStyle: {
//           color: "#9D9D9D"
//         }
//       },
//       axisTick: {
//         show: false
//       }
//     }
//   ],
//   series: [
//     {
//       name: "准静态时域统计值",
//       data: [],
//       type: "line",
//       symbol: "none",
//       itemStyle: {
//         color: "#6DB5C7"
//       },
//       lineStyle: {
//         width: 1
//       }
//     }
//   ]
// };
let option = {
  grid: {
    top: "30",
    left: "70",
    right: "8",
    bottom: "80"
  },
  tooltip: {
    show: true,
    trigger: "axis"
  },
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 40,
      end: 60,
      bottom: 10,
      textStyle: {
          color: '#58637f' //滚动条两边字体样式
      },
      fillerColor: "rgba(39, 114, 240, 0.2)", //选中范围的填充颜色
      handleColor: "#194fdb", //滑块两端的线
      borderColor: "rgba(255,255,255,0.00)" //边框
    // yAxisIndex: [0],
      // filterMode: "empty"
    },
    {
      type: "inside",
      realtime: true,
      start: 40,
      end: 60
    }
  ],
  xAxis: {
    type: "category",
    splitLine: {
      show: false
    },
    axisLabel: {
      color: "#cdd0d8",
      fontSize: 12,
      formatter: function (value) {
        return value.split(" ").join("\n");
      }
    },
    axisLine: {
      lineStyle: {
        color: "#1c2b51",
        // type: "dashed"
      }
    },
    axisTick: {
      show: false
    },
    // nameTextStyle:{
    //   color:'#fff',
    //   padding: [31, 0, 0,-40]
    // },
    // name:'时间',
    data: []
  },
  yAxis: [
    {
      type: "value",
      name: "",
      nameTextStyle: {
        color: "#58637f",
        fontSize: 14
      },
      axisLabel: {
        color: "#58637f",
        fontSize: 14
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: "#1c2b51",
          type: "dashed"
        }
      },
      axisTick: {
        show: false
      }
    },
    {
      type: "value",
      axisLine: {
        show: false,
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      axisTick: {
        show: false
      }
    }
  ],
  series: [
    {
      name: "时域统计值",
      data: [],
      type: "line",
      symbol: "none",
      itemStyle: {
        color: "#6DB5C7"
      },
      lineStyle: {
        width: 1
      }
    }
  ]
};

import mixin from "./mixin";
import moment from "moment";
export default {
  name: "staticTimeDomainAnalysis",
  components: {
    CommonPagination
  },
  mixins: [mixin],
  data() {
    return {
      showNoData1:false,
      showNoData2:false,
      showNoData3:false,
      showNoData4:false,
      columnList: ["最大值", "最小值", "平均值", "有效值", "方差"],
      hiddenTotal: true,
      tableData: [],
      historyTable: [],
      msgList: [
        { value: "", name: "最大值" },
        { value: "", name: "最小值" },
        { value: "", name: "标准差" },
        { value: "", name: "平均值" },
        { value: "", name: "有效值" }
      ],
      pageSize: 5,
      currentPage: 1,
      total: 0,
      loading: false,
      loadingHistory: false,
      loadingSmall: false,
      ruleForm: { period: 2, type: "", quota: "", startTime: "", endTime: "" },
      periodList: [
        // { name: "历史查询", id: 1 },
        { name: "重新计算", id: 2 }
      ],
      typeList: [],
      quotaList: [],
      myChart: {},
      myChart1: {},
      myChart2: {},
      targetId: "", // 测点id
      targetName: "", // 测点编号
      targetUnit: "", // 测点单位
      sort: 1, //排序(0:正序，1:倒序)
      currentPageH: 1,
      totalH: 0,
      showHistoryMain: false,
      showTimeMain: false,
      size: 30000,
      typeId: "", //类型id  静态，动态，振动等
      dataType: 17
    };
  },
  mounted() {
    // this.changeTimeDay(30);
    this.changeTimeDay1();
    // this.ruleForm.endTime = new Date().Format('yyyy-MM-dd hh:mm')
    // this.ruleForm.startTime = new Date(new Date().getTime() - (30 * 60 * 1000)).Format('yyyy-MM-dd hh:mm')
    this.getTypeList(); // 请求监测类型
    // this.querryData();
  },
  methods: {
    queryHistory() {
      console.log("查看历史", this.ruleForm.period);
      if (this.ruleForm.period == 1) {
        console.log("查看历史", this.ruleForm.period);
        this.ruleForm.period = 2;
      } else {
        this.ruleForm.period = 1;
        console.log("查看历史else", this.ruleForm.period);
      }
      this.clearTable0();
      this.querryData();
    },
    changeTimeDay(n) {
      var day = new Date();
      var day1 = day.getTime(); //获得当前时间戳
      var day2 = day1 - n * 24 * 60 * 60 * 1000;
      var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
      var end = moment(day).format("YYYY-MM-DD HH:mm:ss");

      this.ruleForm.startTime = start;
      this.ruleForm.endTime = end;
    },
    changeTimeDay1() {
      var day = new Date();
      var day1 = day.getTime(); //获得当前时间戳
      var day2 = day1 - 120 * 60 * 1000;
      var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
      var end = moment(day).format("YYYY-MM-DD HH:mm:ss");
      this.ruleForm.startTime = start;
      this.ruleForm.endTime = end;
    },

    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    clearTable0() {
      if (this.ruleForm.period == 1) {
        this.changeTimeDay(30);
      }
      if (this.ruleForm.period == 2) {
        this.changeTimeDay1();
      }
    },

    clearTable() {
      this.showHistoryMain = false;
      this.showTimeMain = false;
      this.tableData = [];
    },
    changeType() {
      this.clearTable();
      // this.getQuotaList()
      this.getQuotaTypeList();
    },
    querryData() {
      let time = new Date(this.ruleForm.startTime).getTime();
      let endTime = new Date(this.ruleForm.endTime).getTime();
      let cha = (endTime - time) / 1000;

      if (time > endTime) {
        this.$message({
          message: "开始时间不能晚于结束时间",
          type: "warning"
        });
        return;
      } else if (cha > 1800) {
        // if (this.ruleForm.period == 2) {
        //   this.$message({
        //     message: "时间间隔应小于30分钟",
        //     type: "warning"
        //   });
        //   return;
        // }
      }
      this.handleCurrentChange(1);
      this.showTimeMain = false;
      this.showHistoryMain = false;
    },
    initEcharts(xData, yData, myChart, id, name) {
      myChart = echarts.init(document.getElementById(id));
      let myoption = JSON.parse(JSON.stringify(option));
      console.log("myoption", myoption, xData, yData);
      myoption.series[0].data = yData;
      myoption.series[0].name = name;
      myoption.xAxis.data = xData;
      // myoption.yAxis[0].name = this.targetUnit;
      // myoption.yAxis[0].name = name;
      let max = Math.max.apply(null, yData).toFixed(4);
      let min = Math.min.apply(null, yData).toFixed(4);
      myoption.yAxis[0].max = max;
      myoption.yAxis[0].min = min;

      // myChart.setOption(myoption);
      // window.addEventListener("resize", () => {
      //   myChart.resize();
      // });
      if (id == 'myChart3') {
        var lineColor = '#54a6ff';
      }
      if (id == 'myChartHis1') {
        var lineColor = '#86eaff';
      }
      if (id == 'myChartHis2') {
        var lineColor = '#d3ffef';
      }
      if (id == 'myChart') {
        var lineColor = '#afc1ff';
      }

      var name = this.ruleForm.unit2;
      setTimeout(()=>{
        this.$chart2.line1(id, xData, yData, name, lineColor, max, min);
      },200)



    },
    look(item) {
      this.typeId = item.target.staticDynamicSelected;
      console.log("item", item);
      console.log("类型", this.typeId);
      if (this.typeId == 1) {
        this.columnList = ["最大值", "最小值", "平均值", "标准差"];
      }

      if (this.typeId == 2 || this.typeId == 3) {
        this.columnList = ["最大值", "最小值", "有效值", "平均值", "标准差"];
      }

      let target = item.target;
      this.targetId = target.id;
      this.targetName = target.code;
      this.targetUnit = target.unit;
      let timeDuration = ["其他", "1分钟", "5分钟", "10分钟", "15分钟", "20分钟", "1小时", "1天", "1月"];
      this.targetHour = item.timeDuration === 0 ? item.timeDurationOther : timeDuration[item.timeDuration];
      // this.targetId = 82
      if (this.ruleForm.period === 1) {
        this.showTimeMain = false;
        this.showHistoryMain = true;
        this.handleCurrentChangeH(1);
      } else {
        this.showTimeMain = true;
        this.showHistoryMain = false;
        this.getAssist();
        this.getLineData();
        this.getHistoryBaseLine();
        this.getHisData();
        setTimeout(()=>{
            var chart1 = echarts.init(document.getElementById('myChart'))
            var chart2 = echarts.init(document.getElementById('myChart3'))
            var chart3 = echarts.init(document.getElementById('myChartHis1'))
            var chart4 = echarts.init(document.getElementById('myChartHis2'))
            echarts.connect([chart1, chart2, chart3, chart4])
        }, 200)
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTable();
    },
    handleCurrentChangeH(val) {
      this.currentPageH = val;
      this.loadingHistory = true;
      this.getHistoryList();
    },
    getTypeList() {
      // this.$axios.get(`${this.baseURL}base/baseTargetTypeGroup/`).then((res) => {
      let params = {
        dataType: this.dataType,
        projectId: sessionStorage.getItem("projectId")
      };

      this.$axios.get(`${this.baseURL}data_management/data_type/group`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          // this.typeList = res.data.slice(1, 3);
          // this.ruleForm.type = res.data[1].id;
          this.typeList = res.data;
          this.ruleForm.type = res.data[0].id;
          // this.getQuotaList()
          this.getQuotaTypeList();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getQuotaList() {
      let params = {
        typeGroupId: this.ruleForm.type
      };
      this.$axios.get(`${this.baseURL}base/baseTargetType/byGroupId`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          this.quotaList = res.data;
          this.ruleForm.quota = res.data[0].id;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getTable() {
      let params = {
        projectId: this.$store.state.projectId,
        type: this.ruleForm.quota,
        current: this.currentPage,
        size: this.pageSize
      };
      // this.$axios.get(`${this.baseURL}setttings/settingsDataAnalysis/type/${this.currentPage}/${this.pageSize}`, { params }).then((res) => {
      this.$axios.get(`${this.baseURL}setttings/settingsDataAnalysis/tdomain/nocar/${this.currentPage}/${this.pageSize}`, { params }).then((res) => {
        // console.log('9999999999999999999',res)
        res = res.data;
        let data = res.data;
        console.log(res);
        if (res.errCode === "200") {
          this.tableData = data.records;
          if (this.tableData.length) {
            this.look(this.tableData[0]);
          } else {
            this.showHistoryMain = false;
            this.showTimeMain = false;
          }
          this.total = data.total;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getHistoryList() {
      // 请求历史数据
      let params = {
        targetId: this.targetId,
        current: this.currentPageH,
        size: this.pageSize,
        sort: this.sort,
        startTime: this.ruleForm.startTime,
        endTime: this.ruleForm.endTime
      };
      this.$axios.get(`${this.baseURL}data/history/page/tdomain_nocar/${this.targetId}`, { params }).then((res) => {
        res = res.data;
        let data = res.data;
        console.log(1, data);
        if (res.errCode === "200") {
          let data1 = data.datas;
          if (this.typeId == 1) {
            for (var i = 0; i < data1.length; i++) {
              data1[i].data.splice(3, 1);
            }
            console.log("data1", data1);
          } else {
            for (var i = 0; i < data1.length; i++) {
              var arr = data1[i].data;
              data1[i].data = [arr[0], arr[1], arr[3], arr[2], arr[4]];
            }
            console.log("data2", arr);
          }

          // let list = data.datas
          let list = data1;

          this.historyTable = list;
          this.loadingHistory = false;
          this.totalH = data.total;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getAssist() {
      // 请求辅助信息
      this.loadingSmall = true;
      let params = {
        targetId: this.targetId,
        startTime: this.ruleForm.startTime,
        endTime: this.ruleForm.endTime
      };
      this.$axios.get(`${this.baseURL}data/hisData/tdomain_nocar/${this.targetId}`, { params }).then((res) => {
        res = res.data;
        console.log(222, res);
        console.log(222, typeof res.data);
        if (res.errCode === "200") {
          let list = res.data;
          // list = [9.99996,1.00001,2.6016065517791196,5.496646240533332,6.081239720963915]
          let msgList = this.msgList;

          if (this.typeId == 1) {
            msgList = [
              { value: "", name: "最大值" },
              { value: "", name: "最小值" },
              { value: "", name: "平均值" },
              { value: "", name: "标准差" }
              // {value:'',name:'有效值'}
            ];
            if (list.length == 0) {
              list = [];
              this.msgList = [];
            } else {
              list.splice(3, 1);
              list = [list[0], list[1], list[3], list[2]];
              for (let i = 0; i < list.length; i++) {
                msgList[i].value = list[i].toFixed(3);
              }
              this.msgList = msgList;
            }
          } else {
            msgList = [
              { value: "", name: "最大值" },
              { value: "", name: "最小值" },
              { value: "", name: "有效值" },
              { value: "", name: "平均值" },
              { value: "", name: "标准差" }
            ];
            if (list.length == 0) {
              list = [];
              this.msgList = [];
            } else {
              list = [list[0], list[1], list[4], list[3], list[2]];
              for (let i = 0; i < list.length; i++) {
                msgList[i].value = list[i];
              }
              this.msgList = msgList;
            }
          }

          console.log("list", list);
          // for (let i = 0;i<list.length;i++) {
          //   msgList[i].value = list[i].toFixed(3)
          // }
          // this.msgList = msgList
          console.log('msgList', this.msgList)
        } else {
          this.$message.error(res.errMsg);
        }
        this.loadingSmall = false;
      });
    },
    getLineData() {
      // 请求折线图
      let params = {
        targetId: this.targetId,
        sort: 1,
        startTime: this.ruleForm.startTime,
        endTime: this.ruleForm.endTime,
        size: this.size
      };
      this.$axios.get(`${this.baseURL}data/history/nocar/${this.targetId}`, { params }).then((res) => {
        res = res.data;
        console.log("动态响应项", res);
        let data = res.data;
        if(data && data.length){
          this.showNoData4 = false
        }else{
          this.showNoData4 = true
        }
        let xData = [];
        let yData = [];
        if (res.errCode === "200") {
          for (let item of data) {
            xData.unshift(item.time);
            yData.unshift(item.data[0]);
          }
          // xData=['2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56','2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56']
          // yData=[90, 30, 70, 50, 20, 100, 12,33, 44, 55, 66, 77, 88, 90]
          // this.initEcharts(xData, yData, this.myChart, "myChart", this.targetUnit);
          this.initEcharts(xData, yData, this.myChart, "myChart", this.targetName + "动态响应数据");
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 原始数据
    getHisData() {
      let params = {
        targetId: this.targetId,
        sort: 1,
        startTime: this.ruleForm.startTime,
        endTime: this.ruleForm.endTime,
        size: this.size
      };
      this.$axios.get(`${this.baseURL}data/history/raw/${this.targetId}`, { params }).then((res) => {
        res = res.data;
        console.log("原始数据", res);
        let data = res.data;
        if(data && data.length){
          this.showNoData1 = false
        }else{
          this.showNoData1 = true
        }
        let xData = [];
        let yData = [];
        if (res.errCode === "200") {
          for (let item of data) {
            xData.unshift(item.time);
            yData.unshift(item.data[0]);
          }

          // xData=['2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56','2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56']
          // yData=[90, 30, 70, 50, 20, 100, 12,33, 44, 55, 66, 77, 88, 90]
          this.initEcharts(xData, yData, this.myChart2, "myChart3", this.targetName + "原始数据");
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },

    // 获取极基线数据+准静态
    getHistoryBaseLine() {
      console.log("获取基线数据");
      // this.targetId = 187;
      let params = {
        targetId: this.targetId,
        sort: 1,
        startTime: this.ruleForm.startTime,
        endTime: this.ruleForm.endTime,
        size: this.size
      };

      this.$axios.get(`${this.baseURL}data/history/based/${this.targetId}`, { params }).then((res1) => {
        // this.$axios.get(`${this.baseURL}data/history/current/tdomain/${this.targetId}`, { params }).then((res1) => {
        res1 = res1.data;
        console.log("res1基线", res1);
        let data1 = res1.data;
        if (res1.errCode == "200") {
          this.$axios.get(`${this.baseURL}data/history/tdomain_nocar/${this.targetId}`, { params }).then((res2) => {
            console.log("res2准静态", res2);
            if (res2.data.errCode == "200") {
              // if (!res1.data.length && !res2.data.length) {
              //   console.log(`折线图无数据哦`);

              //   let lineDom = document.getElementById(`myChart2`);
              //   lineDom.innerHTML = ` <div class="noDataMain">
              //     <img src="../../../assets/images/noData.png" alt="" />
              //     <span>暂无数据</span>
              //   </div>`;
              //   lineDom.removeAttribute("_echarts_instance_");
              // } else {
              let yData1 = [],
                xTime1 = [],
                yData2 = [],
                xTime2 = [];

              console.log("res1.data", res1.data, "res2.data.data", res2.data.data);

              for (let i = 0; i < res1.data.length; i++) {
                // yData1.push([res1.data[i].time, res1.data[i].data[0]]);
                yData1.unshift(res1.data[i].data[0]);
                xTime1.unshift(res1.data[i].time);

                // let flag = false;
                // for (let k = 0; k < yData1.length; k++) {
                //   //  && yData1[k][1] == res1.data[i].data[0]
                //   console.log("yData1[k][0]", yData1[k][0], "res1.data[i].time", res1.data[i].time);
                //   if (yData1[k][0] == res1.data[i].time) {
                //     flag = true;
                //   }
                // }
                // console.log("flag", flag);
                // if (!flag) {
                //   yData1.push([res1.data[i].time, res1.data[i].data[0]]);
                //   xTime.push(res1.data[i].time);
                // }
              }
              for (let i = 0; i < res2.data.data.length; i++) {
                // yData2.push([res2.data.data[i].time, res2.data.data[i].data[0]]);
                yData2.unshift(res2.data.data[i].data[2]);
                xTime2.unshift(res2.data.data[i].time);
              }

              console.log("看卡轴这里", yData1, "yData2", yData2);
              let obj = {
                name: this.targetName + "基线校正",
                type: "line",
                data: yData1,

                symbol: "none",
                itemStyle: {
                  color: "#FF7575"
                  // normal: {
                  //   color: "#FF7575",
                  //   lineStyle: {
                  //     color: "#FF7575",
                  //     width: 2,
                  //     type: "solid" //'dotted'虚线 'solid'实线
                  //   }
                  // }
                },
                lineStyle: {
                  width: 1
                }
              };
              let obj1 = {
                name: this.targetName + "准静态效应",
                type: "line",

                symbol: "none",
                data: yData2,
                itemStyle: {
                  color: "#86FFB4"
                  // normal: {
                  //   lineStyle: {
                  //     color: "#86FFB4",
                  //     width: 2,
                  //     type: "solid" //'dotted'虚线 'solid'实线
                  //   }
                  // }
                },
                lineStyle: {
                  width: 1
                }
              };

              // this.$chart1.lineDouble("myChart2", xTime, "", [obj, obj1], ["基线", "历史数据"]);
              this.initHisEcharts("myChartHis1", xTime1, "", [obj], [this.targetName + "基线校正"]);
              this.initHisEcharts("myChartHis2", xTime2, "", [obj1], [this.targetName + "准静态效应"]);
              // }
            } else {
              this.$message.error(res2.errMsg);
            }
          });

          // for (let item of data) {
          //   xData.push(item.time);
          //   yData.push(item.data[0]);
          // }
          // xData=['2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56','2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56', '2020-01-01 08:56']
          // yData=[90, 30, 70, 50, 20, 100, 12,33, 44, 55, 66, 77, 88, 90]
          // this.initEcharts(xData, yData);
        } else {
          this.$message.error(res1.errMsg);
        }
      });
    },
    initHisEcharts(id, xarr, yName, series, lengData) {
      console.log("dada",id,xarr);
      if(id == "myChartHis1" && !xarr.length){
        if(xarr && xarr.length){
          this.showNoData2 = false
        }else{
          this.showNoData2 = true
        }
      }
      if(id == "myChartHis2"){
        if(xarr && xarr.length){
          this.showNoData3 = false
        }else{
          this.showNoData3 = true
        }
      }
      this.myChart1 = echarts.init(document.getElementById(id));
      let Myoption = JSON.parse(JSON.stringify(option));
      // Myoption.series[0].data = yData;
      Myoption.xAxis.data = xarr;
      // Myoption.xAxis.type = "time";
      Myoption.yAxis[0].name = yName;
      Myoption.lengData = lengData;
      // let max = Math.max.apply(null, series[1].data).toFixed(4);
      // let min = Math.min.apply(null, series[1].data).toFixed(4);
      // Myoption.yAxis[0].max = max;
      // Myoption.yAxis[0].min = min;
      Myoption.series = series;

      // this.myChart1.setOption(Myoption);
      // window.addEventListener("resize", () => {
      //   this.myChart1.resize();
      // });
    console.log('series', series)
      var name = yName
      var lineColor = '#27b5f0'
      this.$chart2.line1(id, xarr, series[0].data, name, lineColor);
    }
  }
};
</script>

<style scoped lang="scss">
.main {
  ::v-deep .el-form {
    margin-top: 24px;

    .el-form-item__label {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      text-align: left;
      line-height: 30px;
      width: 96px;
      padding: 0;
    }

    .smallLabel {
      .el-form-item__label {
        width: 80px;
        font-weight: 300;

        &.wid96 {
          width: 96px;
        }
      }
    }

    .el-form-item {
      margin-bottom: 0;
      display: flex;

      &.mB20 {
        margin-bottom: 20px;
      }

      .el-form-item__content {
        margin-left: 0 !important;
        line-height: 30px;
      }
    }

    .line {
      padding: 0 10px;
    }

    .el-input {
      width: 134px;
      height: 30px;

      .el-input__inner {
        font-size: 16px;
        color: #fff;
      }

      .el-select__caret {
        line-height: 30px;
      }

      &.el-date-editor {
        .el-input__inner {
          padding: 0 0 0 8px;
        }

        .el-icon-date {
          display: none;
        }
      }
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 180px;
    }

    .el-date-editor .el-input__icon {
      display: none;
    }
  }

  ::v-deep .el-radio-group {
    .el-radio__inner {
      width: 16px;
      height: 16px;
      background-color: transparent;
      border: 1px solid #787878;

      &:after {
        width: 6px;
        height: 6px;
      }
    }

    .el-radio__label {
      color: #fff;
      font-size: 16px;
    }
  }

  .pageMain {
    margin-top: 50px;
  }

  .chartMain {
    // position: relative;
    // padding-top: 17px;

    .title {
      width: 100%;
      .pl40 {
        padding-left: 40px;
      }
    }
    .title {
  position: relative;
  left: 14px;
  font-size: 16px;
  display: block;
  margin-bottom: 10px;

}
.title::before {
        content: "";
        position: absolute;
        top: 6px;
        left: -12px;
        width: 4px;
        height: 12px;
        opacity: 0.58;
        background: #72d6f3;
      }
    .clumn {
      flex-direction: column;
      border: 1px solid red;
      display: flex;
      flex: 1;
      .charts-wrapper {
        flex: 1;
        border: 1px solid blue;
      }
    }
  }
  .myChart {
    height: 200px;
    overflow: hidden;
  }
  // #myChart {
  //   //width: 100%;
  //   // height: 280px;
  //   height: 200px;
  //   overflow: hidden;
  // }
  // #myChartHis {
  //   //width: 100%;
  //   // height: 280px;
  //   height: 200px;
  //   overflow: hidden;
  // }
  ::v-deep .historyTable {
    border: none;

    thead.is-group.has-gutter {
      th {
        background-color: #2e373c;
      }
    }
  }

  ::v-deep .is-group.has-gutter {
    tr:nth-child(2) {
      display: none;
    }
  }
}

::v-deep .el-radio {
  margin-bottom: 10px;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
.noDataMain{
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 40px;
  background: #0D234A;
  margin-bottom: 20px;
}
.el-radio{
  margin-bottom: 0;
}
</style>
